<template>
    <div class="prepareGoods">
        <rxNavBar title="配货" androidOrIOSFlag="true"></rxNavBar>
        <!-- 搜索栏-->
        <div class="search">
            <div class="search-icon"></div>
            <input type="text" placeholder="合同编号/房源地址/租客姓名、手机号" @keyup.enter="searchSubmit" v-model="keywords">
        </div>
        <!--        下拉菜单 -->
        <div class="downMenuTitle" @click="isShow = !isShow" id="downList">
            <span> {{afterRentStatusMap[statusNum].text}}</span>
            <div class="triangleUp" v-if="!isShow"></div>
            <div class="triangleDown" v-if="isShow"></div>
        </div>
        <div class="reviews">
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :finished-text="noContent? '':'已经到底了'"
                        @load="this.onLoad"
                >

                    <div class="review" v-for="(item,index) in myOrderlist" :key="index">

                        <div :class="item.afterRentStatus=='10'|| item.afterRentStatus=='4'?'top-title-green':item.afterRentStatus=='1'||item.afterRentStatus=='0'?'top-title-orange':item.afterRentStatus=='11' ?'top-title-grey':item.afterRentStatus=='15'?'top-title-violet':'top-title-grey'">
                            {{item.labelText}}
                        </div>

                        <div class="positionAdderss">
                            <div class="position">
                                <span class="position-span-right">{{item.roomDetailedAddress}}</span>
                            </div>
                            <div class="tel_IMg" @click="showMobile({row:item})">
                                <img class="tel" src="../../../assets/images/tel.png">
                            </div>
                        </div>
                        <div class="jump" @click="jump(item)">
                            <div style="width: 100%;border:0.5px solid #f8f8f8;"></div>

                            <div class="reject">
                                <div class="text">收货人：<span class="sp">{{item.applicationGlobaluserName}}</span></div>
                                <div class="text">配货类型：<span class="sp">{{item.goodsAllocationClassName}}</span></div>
                                <div class="text">配货描述：<span class="sp">{{item.distributionDescription}}</span></div>
                            </div>
                            <div style="float: left;width: 100%;border:0.5px solid #f8f8f8;"></div>
                        </div>

                        <div class="buttonTypeA" v-if="roleType=='1' && item.afterRentStatus!=11 &&item.afterRentStatus!=8">
                            <div class="bottom_iconDiv" >
                                <div class="icon" @click="getDoorPassword(item)" v-if="item.publicDeviceId"
                                     :class="item.afterRentStatus == 10 ? '':'trashCanHide'">
                                    <img class="pwd_IMG" src="../../../assets/images/RepairPending-getPwd.png">
                                </div>
                            </div>
                            <div class="buttonType">
                                <div @click="cancelClick(item)"
                                     :class="item.afterRentStatus !=4 && item.afterRentStatus!=11 &&item.afterRentStatus!=8?'':'trashCanHide'"
                                     class="cancelBtn " v-if="checkAuthList(authButtonsList,'cancelDistribution')">
                                    <span>取消</span></div>
                                <div @click="repairClick(item)"
                                     :class="item.afterRentStatus=='9'||item.afterRentStatus==1 ?'':'trashCanHide'"
                                     class="btn" v-if="checkAuthList(authButtonsList,'updateDecoration')">
                                    <span>派单</span></div>
                                <div @click="skip(item)" :class="item.afterRentStatus==15 ?'':'trashCanHide'"
                                     class="btn" v-if="checkAuthList(authButtonsList,'deliveryCompleted')">
                                    <span>完成</span></div>
                                <div @click="reassignmentClick(item)"
                                     :class="item.afterRentStatus == 10?'':'trashCanHide'" class="btn"
                                     v-if="checkAuthList(authButtonsList,'changeDistribution')"><span>接单</span></div>
                                <div @click="acceptedClick(item)" :class="item.afterRentStatus == 4?'':'trashCanHide'"
                                     class="btn" v-if="checkAuthList(authButtonsList,'distributionAcceptance')">
                                    <span>验收</span></div>
                            </div>
                        </div>
                        <div class="buttonTypeA" v-if="roleType=='0'&&item.afterRentStatus == 10">
                            <div class="bottom_iconDiv" @click="getDoorPassword(item)" v-if="item.publicDeviceId">
                                <div class="icon"
                                     :class="item.afterRentStatus == 10 ? '':'trashCanHide'">
                                    <img class="pwd_IMG" src="../../../assets/images/RepairPending-getPwd.png">
                                </div>
                            </div>
                            <div class="buttonType">
                                <div @click="skip(item)" :class="item.afterRentStatus==10 ?'':'trashCanHide'"
                                     class="btn"><span>完成</span></div>
                            </div>
                        </div>
                    </div>
                    <!--拨打电话弹窗-->
                    <dial-mobile :ownerMobile="temporaryOwnerMobile" :userName="temporaryOwnerName"
                                 :dialogTitle="dialogTitle" :mobileShow="isMobileShow" @hideDialog="hideDialog">
                    </dial-mobile>
                    <!--        ===================================获取密码=========================================-->
                    <van-overlay :show="pwdShow" @click="pwdShow = false">
                        <div class="wrapper" @click.stop>
                            <div class="block">
                                <!--  获取密码的div-->
                                <div class="getPassword">
                                    获取密码
                                </div>
                                <!--  门锁密码展示的DIV-->
                                <div class="LockPassWord" v-if="doorPassWord != undefined && doorPassWord != ''">
                                    <p class="passwordText">公区密码</p>
                                    <p class="password">{{doorPassWord}} </p>
                                </div>
                                <div class="LockPassWord" v-if="doorPassWord != undefined && doorPassWord != '' && privatePwd != undefined && privatePwd != ''"></div>
                                <div class="LockPassWord" v-if="privatePwd != undefined && privatePwd != ''">
                                    <p class="passwordText" >房门密码</p>
                                    <p class="password">{{privatePwd}} </p>
                                </div>
                            </div>
                            <div class="closeImg" @click="closeMobileModel">
                            </div>
                        </div>
                    </van-overlay>
                    <div v-if="isMobileShowA">
                        <div>
                            <get-door-password :doorPassWord="temporaryDoorPassWord"
                                               :publicDoorPassWord="temporaryPublicDoorPwd"
                                               :isMobileShow="isMobileShowA"
                                               @hideDialog="hideDialogA"></get-door-password>
                        </div>
                        <div class="closeImg" @click="closeMobileModel">
                        </div>
                    </div>
                </van-list>
                <common-empty v-if="noContent"></common-empty>
            </van-pull-refresh>
        </div>
        <!--        ==========================下拉框的弹窗==============================-->
        <van-popup v-model="isShow" position="bottom">
            <van-picker show-toolbar :columns="afterRentStatusMap" @cancel="isShow = false"
                        :default-index="afterRentStatusNum" value-key="text" @confirm="downMenu"/>
        </van-popup>

        <!--        取消弹框-->
        <van-popup v-model="cancelShow" class="van-popup--bottom" position="bottom" :style="{ height: '45%' }">
            <img style="float: left;margin: 20px 5px 0 15px;width: 15px;height: 15px;"
                 src="../../../assets/images/colon.png">
            <div style="margin-top: 18px;font-size: 15px;font-weight: bold">确认</div>
            <div style="margin-left: 35px;margin-bottom: 15px;font-size: 15px;font-weight: bold">取消该配货单？</div>
            <textarea placeholder="输入取消原因" v-model="cancellationReason" class="cancelTest"/>
            <van-button @click="cancelDistribution()"
                        :class="!cancellationReason?'saveButton_Disable':'saveButton_Enable'"
                        :disabled="!cancellationReason" style="margin-top: 30px">确认
            </van-button>
        </van-popup>

        <!--        配货的弹窗-->
        <van-popup class="van-popup--bottom" v-model="prepareGoods" position="bottom" :style="{ height: '90%' }">
            <img style="float: left;margin: 20px 5px 0 15px;width: 15px;height: 15px;"
                 src="../../../assets/images/colon.png">
            <div style="margin-top: 18px;margin-bottom: 20px;font-size: 15px;font-weight: bold">配货派单</div>
            <!--        送货人-->

            <div id="prepareGoodsId" class="part-inputpart-row" @click="clickacceptanceNameShow">
                <span :class="!selectName? 'maker':'orange'">• </span>
                <span class="part-inputpart-row-header">送货人</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext"
                      :class="selectName ? 'optionSelectedColor':'' ">{{selectName}}</span>
                <img class="part-inputpart-row-right"
                     :class=" isNameShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                     src="../../../assets/images/triangle.png">
            </div>

            <!--        可选择的送货人选项-->
            <div>
                <!--        可选择的保洁类型选项-->
                <van-popup v-model="isNameShow" position="bottom">
                    <van-picker show-toolbar :columns="personList" value-key="userName"
                                @cancel="isNameShow = false" @confirm="selectBankTypea"/>
                </van-popup>
            </div>

            <!--            费用承担方-->
            <div id="undertaker" class="part-inputpart-row" @click="clickUndertakerShow">
                <span :class="selectUndertaker == ''? 'maker':'orange'">• </span>
                <span class="header">费用承担方</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext" :class="selectUndertaker ? 'optionSelectedColor':'' ">{{selectUndertaker}}</span>
                <img class="part-inputpart-row-right"
                     :class=" isUndertakerShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                     src="../../../assets/images/triangle.png">
            </div>

            <!--        承担方选项-->
            <div>
                <van-popup v-model="isUndertakerShow" position="bottom">
                    <van-picker show-toolbar :columns="costBear" value-key="dictionaryTitle"
                                @cancel="isUndertakerShow = false" @confirm="selectBankType"/>
                </van-popup>
            </div>

            <!--预计费用-->
            <div class="part-inputpart-row">
                <span :class="rent == ''? 'maker':'orange'">• </span>
                <span class="part-inputpart-row-enablenon part-inputpart-row-header">预计费用</span>
                <span class="content-divide">|</span>
                <span style="font-size: 14px">￥</span>
                <input type="number" v-model="rent" placeholder="输入金额" :class="rent==''? 'makerText':'orangeText'">
                <span class="part-inputpart-row-right">元</span>
            </div>

            <!--                    上门时间-->
            <div id="checkInDatePanel" class="part-inputpart-row" @click="clickCheckInDateShow">
                <span :class="0==checkInDate.trim().length ? 'maker':'orange'">• </span>
                <span class="part-inputpart-row-header">时间</span>
                <span class="content-divide">|</span>
                <span class="date-graytext">{{checkInDate}}</span>
                <img class="part-inputpart-row-right-timepick" src="../../../assets/images/timepick.png">
            </div>
            <div>
                <van-popup v-model="isCheckInDateShow" position="bottom">
                    <van-datetime-picker
                            v-model="currentDate"
                            type="date"
                            title="选择年月日"
                            item-height="35px"
                            @confirm="checkInDateConfirm"
                            @cancel="checkInDateCancel"
                    />
                </van-popup>
            </div>

            <div class="feedback">
                <div class="feedbackText">
                    <span class="spanOne">配货描述:</span>
                    <span>(非必填)</span>
                </div>
                <textarea class="feedbackTextarea" placeholder="配货描述" v-model="dealResult"></textarea>
            </div>
            <van-button @click="saveDistributionOrder()"
                        :class="!selectName || !selectUndertaker || !rent  || !checkInDate?'saveButton_Disable':'saveButton_Enable'"
                        :disabled="!selectName || !selectUndertaker || !rent  || !checkInDate"
            >确定
            </van-button>
        </van-popup>

        <!--        改派弹窗-->

        <van-popup class="van-popup--bottom" v-model="reassignment" position="bottom" :style="{ height: '50%' }">
            <img style="float: left;margin: 20px 5px 0 15px;width: 15px;height: 15px;"
                 src="../../../assets/images/colon.png">
            <div style="margin-top: 18px;margin-bottom: 20px;font-size: 15px;font-weight: bold">配货派单</div>

            <!--        送货人-->
            <div id="" class="part-inputpart-row" @click="reassignmentNameShow">
                <span :class="!reassignmentName? 'maker':'orange'">• </span>
                <span class="part-inputpart-row-header">送货人</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext" :class="selectName ? 'optionSelectedColor':'' ">{{reassignmentName}}</span>
                <img class="part-inputpart-row-right"
                     :class=" isReassignmentShow   ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                     src="../../../assets/images/triangle.png">
            </div>
            <div>
                <!--        可选择的保洁类型选项-->
                <van-popup v-model="isReassignmentShow  " position="bottom">
                    <van-picker show-toolbar :columns="list" value-key="userName"
                                @cancel="isReassignmentShow= false" @confirm="reassignmentConfirm"/>
                </van-popup>
            </div>

            <!--                    上门时间-->
            <div id="checkInDatePanelA" class="part-inputpart-row" @click="clickCheckInDateShow">
                <span :class="0==checkInDateA.trim().length ? 'maker':'orange'">• </span>
                <span class="part-inputpart-row-header">时间</span>
                <span class="content-divide">|</span>
                <span class="date-graytext">{{checkInDateA}}</span>
                <img class="part-inputpart-row-right-timepick" src="../../../assets/images/timepick.png">
            </div>
            <div>
                <van-popup v-model="isCheckInDateShow" position="bottom">
                    <van-datetime-picker
                            v-model="currentDate"
                            type="date"
                            title="选择年月日"
                            item-height="35px"
                            @confirm="checkInDateConfirmA"
                            @cancel="checkInDateCancel"
                    />
                </van-popup>
            </div>
            <van-button @click="repairBtnA"
                        :class="!reassignmentName || !checkInDateA?'saveButton_Disable':'saveButton_Enable'"
                        :disabled="!reassignmentName || !checkInDateA"
            >确定
            </van-button>
        </van-popup>


        <!--        验收弹窗-->
        <van-popup class="van-popup--bottom" v-model="acceptedShow" position="bottom"
                   :style="{ maxHeight: '80%',overflowY:'scroll',height : 'auto'  }">
            <img style="float: left;margin: 20px 5px 0 20px;width: 15px;height: 15px;"
                 src="../../../assets/images/colon.png">
            <div style="margin-top: 18px;margin-bottom: 0;font-size: 15px;font-weight: bold">验收</div>
            <div class="normalBlock">
                <span class="maker" :class="{'orange':(isPass||isReject)}">• </span>
                <span class="blockTitle">验收结果</span>
                <div class="rightText">
                    <span class="passText" :class="{'choosedStyle':isPass}" @click="choosePass()">通过</span>
                    <span class="content-divide"> | </span>
                    <span class="rejectText" :class="{'choosedStyle':isReject}" @click="chooseReject">驳回</span>
                </div>
            </div>
            <div class="normalBlock">
                <span :class="value == ''?'maker':'orange'">• </span>
                <span class="blockTitle">施工负责人评价</span>
                <div class="star">
                    <van-rate v-model="value" :count="5"/>
                </div>
            </div>
            <!--            费用承担方-->
            <div id="undertakera" class="normalBlock" @click="clickWorkeShow" v-if="!isReject">
                <span :class="workerName == ''? 'maker':'orange'">• </span>
                <span class="header">费用承担方</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext"
                      :class="workerName ? 'optionSelectedColor':'' ">{{workerName}}</span>
                <img class="part-inputpart-row-right"
                     :class=" isWorkerShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                     src="../../../assets/images/triangle.png">
            </div>
            <!--预计费用-->
            <div class="normalBlock" v-if="!isReject">
                <span :class="lastFee == ''? 'maker':'orange'">• </span>
                <span class="header">最终费用</span>
                <span class="content-divide">|</span>
                <span style="font-size: 12px;margin-left: 10px">￥</span>
                <input type="tel" v-model="lastFee" placeholder="输入金额" style="background-color: white"
                       :class="lastFee == ''? 'makerText':'orangeText'" class="part-inputpart-row-graytext">
                <span class="part-inputpart-row-right">元</span>
            </div>

            <!--            拒接理由-->
            <div id="undertakerb" class="normalBlock" @click="refuseReasonShow" v-if="isReject">
                <span :class="refuseReasonName == ''? 'maker':'orange'">• </span>
                <span class="header">拒绝理由</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext" :class="refuseReasonName ? 'optionSelectedColor':'' ">{{refuseReasonName}}</span>
                <img class="part-inputpart-row-right"
                     :class=" isWorkerShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' "
                     src="../../../assets/images/triangle.png">
            </div>
            <!--        承担方选项-->
            <div>
                <van-popup v-model="isWorkerShow" position="bottom">
                    <van-picker show-toolbar :columns="costBear" value-key="dictionaryTitle"
                                @cancel="isWorkerShow = false" @confirm="workerChoosetype"/>
                </van-popup>
            </div>
            <!--            拒接理由选项-->

            <div>
                <van-popup v-model="isRejectText" position="bottom">
                    <van-picker show-toolbar :columns="refuseReason" value-key="dictionaryTitle"
                                @cancel="isRejectText = false" @confirm="refuseReasonChoosetype"/>
                </van-popup>
            </div>
            <!--验收备注-->
            <div class="row2">
                <div class="acceptedRemake">{{isReject?'描述':'验收备注'}}</div>
                <textarea placeholder="" class="remake" v-model="accepteReason" v-if="!isReject"/>
                <textarea placeholder="描述" class="remake" v-model="description" v-else/>
                <div class="uploadDiv" v-if="!isReject">
                    <van-uploader v-model="fileList" :after-read="afterReadTest" class="uploader" multiple
                                  :max-count="10" preview-size="98" :before-delete="deleteImage">
                        <div class="btncc">
                            <van-icon name="plus" size="30px" color="#dddddd"/>
                        </div>
                    </van-uploader>
                </div>
            </div>
            <van-button @click="saveDistributionAcceptance()"
                        :class="!isPass&&!isReject ||!lastFee ||(isReject ? !refuseReasonName : !workerName) || !value> 1 ?'saveDisable':'saveEnable'"
                        :disabled=" !isPass&&!isReject || !lastFee ||(isReject ? !refuseReasonName : !workerName) || !value">
                确定
            </van-button>
        </van-popup>
        <loadingFlag v-if="loadingFlag"></loadingFlag>
    </div>

</template>

<script>
    import {
        Button,
        DatetimePicker,
        DropdownItem,
        DropdownMenu,
        NavBar,
        Popup,
        Search,
        Uploader,
        Rate,
        Overlay,
        Picker
    } from "vant";
    import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile';
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import loadingFlag from "../../afterRent/maintain/LoadingFlag";
    import getDoorPassword from '../../../components/rongxun/rx-doorPassword/GetDoorPassword'
    import {
        afterRentDistributionList,
        queryBaseData,
        saveDistributionOrderInit,
        saveDistributionOrder,
        cancelDistribution,
        saveDistributionAcceptance,
        distributionDetailDispatchInit,
        base64Upload,
        changeDistributionInit,
        saveChangeDistribution,
        seeWithGetPassword,
        getStaffFuntionModelList,
        distributionDetailItemInit,
        userGetPassword
    } from "../../../getData/getData";
    import {globaluserId, responseUtil, getStaffId} from "../../../libs/rongxunUtil";
    import {checkAuthList} from "../../../libs/rongxunUtil"
    import Vue from 'vue';
    import {List, PullRefresh, Toast, icon} from 'vant';

    Vue.use(PullRefresh, List, Toast, Popup, Picker, icon);
    export default {
        components: {
            [NavBar.name]: NavBar,
            [Search.name]: Search,
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [Button.name]: Button,
            [DatetimePicker.name]: DatetimePicker,
            [Uploader.name]: Uploader,
            [Rate.name]: Rate,
            [icon.name]: icon,
            [Overlay.name]: Overlay,
            [List.name]: List,
            [PullRefresh.name]: PullRefresh,
            [Toast.name]: Toast,
            dialMobile,
            rxNavBar,
            loadingFlag,
            getDoorPassword,
        },
        props: {
            ownerMobile: {
                type: String,
                default: ''
            },
            userName: {
                type: String,
                default: ''
            },
            mobileShow: {
                type: Boolean,
                default: false
            },
        },
        name: "prepareGoods",
        data() {
            return {
                roleType: '1',  //标识，用于判断入口是我的页面的租后工单（装修工人等 是 0   否 1）
                loadingFlag: false,
                pwdShow: false,
                doorPassWord: '暂无',
                privatePwd: '暂无',
                //拨打电话弹窗 start
                isMobileShowA: false,
                temporaryOwnerMobile: '',
                temporaryOwnerName: '',
                dialogTitle: '联系业主',
                password: '',
                //获取密码 start
                isMobileShow: false,
                temporaryDoorPassWord: '',
                temporaryPublicDoorPwd: '',
                //获取密码 end
                //拨打电话弹窗 end
                show: false,
                fileList: [
                    // { url: 'https://img.yzcdn.cn/vant/leaf.jpg' },
                    // // Uploader 根据文件后缀来判断是否为图片文件
                    // // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
                    // { url: 'https://cloud-image', isImage: true },
                ],
                value: 1,
                cancel: '取消',
                finishDispatch: '验收完成',
                back: '退回',
                prepareGood: '派单',
                finish: '完成',

                //上门时间
                isCheckInDateShow: false,
                currentDate: new Date(),
                checkInDate: '',
                isPass: false,
                isReject: false,
                rent: '',
                expectedDate: '',
                isExpectedDateShow: false,
                cancelShow: false,
                prepareGoods: false,
                acceptedShow: false,
                selectName: '选择送货人员',
                selectUndertaker: '请选择',
                isNameShow: false,
                isUndertakerShow: false,
                isUndertakerSelect: '',
                isNameSelect: '',
                authButtonsList: [],
                repairer: [
                    '周年年',
                    '李国刚',
                    '张凯凯',
                ],
                undertakerArr: [
                    '甲方',
                    '乙方',
                ],
                //所有状态列表
                infoList: [],

                //下拉菜单
                isShow: false,
                afterRentStatusMap: [
                    {text: '全部', value: ''},
                    {text: '待审批', value: 0},
                    {text: '待处理', value: 1},
                    {text: '已取消', value: 8},
                    {text: '配货中', value: 10},
                    {text: '待验收', value: 4},
                    {text: '已验收', value: 11}
                ],
                afterRentStatus: {
                    dictionaryName: "toExamined",
                    dictionaryTitle: "全部",
                    dictionaryValue: ""
                },
                afterRentStatusList: [],
                statusNum: 1,
                afterRentStatusNum: '',

                //接口数据
                //改派按钮变量
                reassignment: false,//改派弹窗
                isReassignmentShow: false,//改派工人弹窗
                reassignmentName: '',//工人姓名
                list: [],//改派页面初始化列表
                checkInDateA: '',//展示的时间,
                timeA: '',//入参时间
                changeId: '',//改派配货id
                workerId: '',//工人id
                dealResult: '',//配货描述
                isWorkerShow: false,//验收工人选择弹窗
                workerName: '',//
                isRejectText: false,
                refuseReason: [],//拒接理由
                description: '',//拒绝描述
                refuseReasonName: '',
                refuseReasonId: '',

                loading: false,
                finished: false,
                currentPage: '1',//当前页
                pageCount: '',//总页数
                numberPage: '10',//每页条数
                count: 0,
                type: "0",
                isLoading: false,
                seeWith_id: '',//带看id
                msg: '',
                noContent: false,//判断是否显示空页面
                myOrderlist: [],
                staff_id: '',// 员工
                distributionStatus: '',// 紧急程度
                supplier: '',// 供应商
                timeType: '',// 时间类型
                beginTime: '',// 开始时间
                endTime: '',// 结束时间distributor
                distributor: '',// 工人
                person: '',
                queryTitle: '',
                currentLabel: '',
                did: '',//配货id
                id: '',//租后表id
                time: '',//预约时间
                keywords: '',//关键字
                costBear: '',//费用承担方
                costBearNumber: '',//费用承担类型
                cancellationReason: '',//取消原因
                personList: [],//配货工人列表
                acceptCode: '',//验收配货id
                costBearCode: '',//费用承担类型
                lastFee: '',//最终费用
                radioSelect: '',//通过和驳回
                accepteReason: '',//验收备注
                picList: [{}],//图片
                tableList:[],
                setHouse_id:'',
                roomHouse_id:'',
                checkedRowData:{}   //选中的接单数据
            }

        },
        beforeRouteEnter(to, from, next) {
            //清除本地localStorage缓存
            if (from.name == null) {
                localStorage.removeItem('currentLabel');
                localStorage.removeItem('type');
                localStorage.removeItem('inputValue');
                localStorage.removeItem('currentLabelPublic');
            }
            next()
        },
        created: function () {
            this.queryBaseData();

            // this.seeWithGetPassword();
            //标识，用于判断入口是我的页面的租后工单（装修工人等 是 0   否 1）
            if (this.$route.query.roleType) {
                this.roleType = this.$route.query.roleType;
                localStorage.setItem('currentLabel', JSON.stringify(4));
            } else {
                this.getStaffFuntionModelList();
            }
            let tempCurrentLabel = localStorage.getItem('currentLabel'); //先读取local里存储的历史记录
            if ((tempCurrentLabel == null || tempCurrentLabel == "undefined") && this.$route.query.currentLabel != null && this.$route.query.currentLabel != undefined) {
                let current = this.$route.query.currentLabel
                localStorage.setItem('currentLabel', JSON.stringify(Number(current)))
            }
            let currentLabel = localStorage.getItem('currentLabel'); //先读取local里存储的历史记录
            this.statusNum = currentLabel == null || currentLabel == "undefined" ? 1 : Number(currentLabel);

        },

        methods: {

            //权限
            getStaffFuntionModelList() {
                var that = this
                let data = {}
                data.staff_id = getStaffId()
                data.menuName = 'afterRentDistribution_index'
                getStaffFuntionModelList(data).then(function (response) {
                    console.log(response)
                    responseUtil.dealResponse(that, response, () => {
                        that.authButtonsList = response.data.data.data
                    })
                })
            },
            checkAuthList,

            //下拉框
            downMenu(item, values) {
                localStorage.setItem('currentLabel', JSON.stringify(values))
                this.afterRentStatusNum = item.value,
                    this.statusNum = values
                this.isShow = false;
                this.upList()
            },
            //上拉加载方法
            upList() {
                this.default();
                this.loading = true;
                this.finished = false
                this.isLoading = false;
                this.onLoad()
            },
            //承担方事件
            selectBankType(item) {
                this.isUndertakerShow = false
                this.isUndertakerSelect = true
                this.selectUndertaker = item.dictionaryTitle
                this.costBearNumber = item.dictionaryValue
            },

            //工人确认事件
            selectBankTypea(item) {
                this.selectName = item.userName
                this.isReassignmentShow = false
                this.isNameSelect = true
                this.person = item.id
                this.isNameShow = false;
            },

            //筛选
            searchSubmit() {
                this.default();
                this.onLoad();
            },
            //获取房门密码
            getDoorPassword(item) {
                let that = this;
                // that.temporaryDoorPassWord = that.password
                // that.temporaryPublicDoorPwd = that.password
                // that.isMobileShowA = !that.isMobileShowA;
                that.setHouse_id = item.setHouse_id
                that.roomHouse_id = item.roomHouse_id
                that.userGetPassword()
            },

            // //获取密码接口
            // seeWithGetPassword: function () {
            //     var that = this
            //     let initData = {
            //         seeWith_id: '24',
            //         longitude: '121.661353',
            //         latitude: '38.925833'
            //     }
            //     seeWithGetPassword(initData).then(function (response) {
            //         console.log(response)
            //         responseUtil.dealResponse(that, response, () => {
            //             if (response.data.code == 0) {
            //                 that.password = response.data.data.password;
            //             }
            //         })
            //     })
            // },
            userGetPassword() {
                let that = this
                let initData = {}
                initData.user_id = globaluserId()
                initData.setHouse_id = that.setHouse_id
                initData.roomHouse_id = that.roomHouse_id
                initData.type = "4"
                userGetPassword(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                        that.doorPassWord = response.data.data.publicPwd
                        that.privatePwd = response.data.data.privatePwd
                    })
                    that.pwdShow = true

                })
            },
            //图片删除时处理
            deleteImage(file) {
                for (let i = 0; i < this.picList.length; i++) {
                    if (this.picList[i].path == file.path) {
                        this.picList.splice(i, 1);
                    }
                }
                return true
            },

            //图片多选上传
            afterReadTest(file) {
                // this.loadingFlag = true;
                let fileLength = parseInt(file.length) - parseInt(1)
                if (file.length > 1) {
                    for (let i = 0; i < file.length; i++) {
                        let fileDetail = file[i];
                        this.afterRead(fileDetail, i, fileLength);
                    }
                } else {
                    this.afterRead(file);
                }
            },

            afterRead(file, i, fileLength) {
                let that = this
                let initData = {
                    base64: file.content
                }
                that.loadingFlag = true;
                base64Upload(initData).then(function (response) {
                    that.loadingFlag = false;
                    responseUtil.dealResponse(that, response, () => {
                        file.path = response.data.data.path
                        that.picList.push({"path": response.data.data.path})
                        console.log(that.picList)
                        if (i == fileLength) {
                            that.loadingFlag = false
                        }
                    })
                })
            },

            //验收状态初始化接口
            distributionDetailDispatchInit: function () {
                let that = this
                let initData = {
                    id: that.acceptCode,
                }
                distributionDetailDispatchInit(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if (response.data.code == 0) {
                            that.costBearCode = response.data.data.item.costBearCode;
                            that.lastFee = response.data.data.item.distributionFee//最后费用
                        }
                    })
                })
            },

            //验收状态保存接口
            saveDistributionAcceptance: function () {
                let that = this
                // debugger
                let initData = {
                    user_id: getStaffId(),
                    id: that.acceptCode,
                    radioSelect: that.radioSelect,
                    costBear: that.workerId,
                    finalCost: that.lastFee,
                    remarks: that.accepteReason,
                    picList: that.picList,
                    describe: that.description,
                    pingjia: that.value,
                    rejectionReason: that.refuseReasonId//驳回原因
                }

                saveDistributionAcceptance(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if (response.data.code == 0) {
                            responseUtil.alertMsg(that, response.data.msg)
                            that.acceptedShow = false
                            that.onRefresh()
                        } else if (response.data.code != 0) {
                            responseUtil.alertMsg(that, response.data.msg)
                        } else {
                            responseUtil.alertMsg(that, response.data.msg)
                        }
                    })
                })
            },

            //派单保存接口
            saveDistributionOrder: function () {
                let that = this
                let initData = {
                    did: that.did,
                    user_id: getStaffId(),
                    person: that.person,
                    handoverDate: that.time,
                    totalMoney: 0,
                    costBear: that.costBear,
                    distributionCost: that.rent,
                    tableList:that.tableList,
                    afterRentDesprition: that.dealResult//配货描述
                }
                saveDistributionOrder(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.dealResult = '';
                        if (response.data.code == 0) {
                            responseUtil.alertMsg(that, response.data.msg)
                            that.prepareGoods = false
                            that.onRefresh();
                        } else if (response.data.code != 0) {
                            responseUtil.alertMsg(that, response.data.msg)
                        } else {
                            responseUtil.alertMsg(that, response.data.msg)
                        }
                    })
                })
            },

            //配货取消接口
            cancelDistribution: function () {
                let that = this
                let initData = {
                    id: that.id,
                    user_id: getStaffId(),
                    cancellationReason: that.cancellationReason,
                }
                cancelDistribution(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if (response.data.code == 0) {
                            responseUtil.alertMsg(that, response.data.msg)
                            that.cancelShow = false
                            that.onRefresh();
                        } else if (response.data.code != 0) {
                            responseUtil.alertMsg(that, response.data.msg)
                        } else {
                            responseUtil.alertMsg(that, response.data.msg)
                        }
                    })
                })
            },

            //下拉框选项初始化
            afterRentDistributionList: function () {
                var that = this
                if (that.statusNum) {
                    console.log("that.statusNum==" + that.statusNum)
                    that.afterRentStatusNum = that.afterRentStatusMap[that.statusNum].value
                } else {
                    that.afterRentStatusNum = that.afterRentStatusMap[that.statusNum].value
                }
                let initData = {
                    currentPage: that.currentPage,
                    numberPage: that.numberPage,
                    // staff_id:getStaffId(),
                    keywords: that.keywords,
                    currentLabel: that.afterRentStatusNum,
                    roleType: that.roleType
                }
                if (that.roleType == '1') {
                    initData.staff_id = getStaffId()
                } else if (that.roleType == '0') {
                    initData.staff_id = globaluserId()  //如果登录人是装修负责人（非员工），则使用其用户id
                }
                console.log(initData)
                afterRentDistributionList(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if (response.data.code == 0) {
                            var list = []
                            that.pageCount = response.data.data.totalPage//总页数
                            var pageCount = response.data.data.pageCount//总条数
                            list = response.data.data.data
                            // debugger
                            console.log(response.data.data.data)
                            if (pageCount == 0 || that.pageCount == 0 || !list) {//没有数据时
                                that.noContent = true//显示空状态
                                that.loading = false
                                that.finished = true
                            }
                            if (list && that.pageCount != 0) {//有数据时
                                var currentPage = parseInt(that.currentPage);//当前页
                                var pages = that.pageCount;//总页数
                                if (pages >= currentPage) {//当前页不大于总页数
                                    that.currentPage = currentPage + 1
                                    for (let i = 0; i < list.length; i++) {
                                        that.myOrderlist.push(list[i])
                                    }
                                    that.loading = false
                                    that.noContent = false//不显示空状态
                                } else {
                                    that.loading = false
                                    that.finished = true
                                    // that.noContent=true
                                }
                            }
                        } else if (response.data.code != 0) {
                            responseUtil.alertMsg(that, response.data.msg)
                        } else {
                            responseUtil.alertMsg(that, response.data.msg)
                        }
                    })
                })
            },

            //初始化字典
            queryBaseData: function () {
                let that = this
                let initData = {
                    dbName: ['distributionType', 'refuseReason'],
                    fdName: ["COSTBEARMAP", "AFTERRENTSTATUSMAP"]
                }

                queryBaseData(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                        if (response.data.code == 0) {
                            that.costBear = response.data.data.COSTBEARMAP
                            that.refuseReason = response.data.data.refuseReason
                            if (response.data.data.AFTERRENTSTATUSMAP.length != 0) {
                                response.data.data.AFTERRENTSTATUSMAP.splice(12, 3)
                                response.data.data.AFTERRENTSTATUSMAP.splice(2, 2)
                                response.data.data.AFTERRENTSTATUSMAP.splice(3, 3)
                                response.data.data.AFTERRENTSTATUSMAP.splice(4, 1)
                                response.data.data.AFTERRENTSTATUSMAP.unshift(that.afterRentStatus)
                                that.afterRentStatusList = response.data.data.AFTERRENTSTATUSMAP
                            }


                        } else if (response.data.code != 0) {
                            responseUtil.alertMsg(that, response.msg)
                        } else {
                            responseUtil.alertMsg(that, response.msg)
                        }


                    })
                })
                return true
            },

            //改派初始化接口
            changeDistributionInit: function () {
                let that = this
                let initData = {
                    user_id: getStaffId()
                }
                changeDistributionInit(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if (response.data.code == 0) {
                            that.list = response.data.data.list;
                        }
                    })
                })
            },

            //改派保存接口
            saveChangeDistribution: function () {
                let that = this
                let initData = {
                    id: that.changeId,
                    user_id: getStaffId(),
                    personnel: that.workerId,
                    handoverDate: that.timeA,
                    did:that.checkedRowData.did,
                    is:'1'
                }
                console.log(initData)
                saveChangeDistribution(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        // debugger
                        if (response.data.code == 0) {
                            responseUtil.alertMsg(that, response.data.msg)
                            that.onRefresh()
                            return true;
                        } else if (response.data.code != 0) {
                            responseUtil.alertMsg(that, response.data.msg)
                            return false;
                        } else {
                            responseUtil.alertMsg(that, response.data.msg)
                            return false;
                        }
                    })
                })
                return true;
            },
            //分页
            onLoad: function () {
                // this.queryBaseData();
                this.afterRentDistributionList();

            },

            //刷新
            onRefresh() {
                this.default();
                this.loading = true;
                this.finished = false
                this.isLoading = false;
                this.onLoad()
                responseUtil.alertMsg(this, "刷新成功")
            },

            //默认值
            default() {
                this.pageCount = ''
                this.currentPage = '1'
                this.numberPage = '10'
                this.loading = false,
                    this.finished = false,
                    this.myOrderlist = []
            },

            //拨打电话
            showMobile(e) {
                let that = this;
                that.temporaryOwnerMobile = e.row.applicationGlobaluserMoblile;
                that.temporaryOwnerName = e.row.applicationGlobaluserName
                that.isMobileShow = !that.isMobileShow;
            },

            //关闭拨打电话弹窗
            hideDialog() {
                let that = this;
                that.isMobileShow = false;
            },
            //关闭获取密码弹窗
            hideDialogA() {
                let that = this;
                that.isMobileShowA = false;
            },

            //返回上一页
            leftReturn() {
                this.$router.go(-1);
            },

            // 弹框按钮--点击触发弹框--确认后弹框消失
            cancelClick(item) {
                this.cancellationReason = '',
                    this.id = item.id
                this.cancelShow = true
            },
            acceptedClick(item) {
                this.isPass = false,
                    this.isReject = false,
                    this.accepteReason = '',
                    this.workerName = '',
                    this.value = 1;
                this.fileList = [],
                    this.acceptCode = item.did
                this.distributionDetailDispatchInit();
                this.acceptedShow = true
            },

            //改派按钮
            reassignmentClick(item) {
                console.log(item)
                this.checkedRowData=item
                this.reassignmentName = '',//工人姓名
                    this.checkInDateA = '',//展示的时间,
                    this.changeId = item.afterRent_id
                this.reassignment = true
                this.changeDistributionInit();
            },
            //日期选择组件 点击确认键后执行方法
            checkInDateConfirmA(value) {
                var date = new Date(value)
                this.checkInDateA = this.formatDate(date)
                this.timeA = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + 'T' + date.getHours() + ':' + date.getMinutes() + ':' + '00' + '.000Z'
                this.isCheckInDateShow = false
            },
            reassignmentNameShow() {
                this.isReassignmentShow = !this.isReassignmentShow;
            },

            //工人确认事件
            reassignmentConfirm(item) {
                this.reassignmentName = item.userName
                this.workerId = item.id
                this.isReassignmentShow = !this.isReassignmentShow
                this.reassignment = true
            },
            //改派确认接口
            repairBtnA() {
                // this.saveChangeDistribution();
                // debugger
                if (this.saveChangeDistribution()) {
                    this.reassignment = false,//改派弹窗
                        this.isReassignmentShow = false,//改派工人弹窗
                        this.reassignmentName = '',//工人姓名
                        this.list = [],//改派页面初始化列表
                        this.checkInDateA = '',//展示的时间,
                        this.timeA = '',//入参时间
                        this.changeId = '',//改派配货id
                        this.workerId = ''
                }
                //this.onRefresh();
            },
            //完成
            //详情页跳转
            jump(item) {
                this.$router.push({
                    name: 'distributionViewDetails',
                    query: {
                        id: item.did,//配货id
                        afterRentStatus: item.afterRentStatus//状态码
                    }
                })
            },
            //维修完成跳转页面
            skip(item) {
                this.$router.push({
                    name: 'prepareKeepInfo',
                    query: {
                        id: item.did,//配货id
                        roleType: this.roleType
                    }
                })
            },

            //派单按钮
            repairClick(item) {
                this.did = item.did
                this.checkInDate = '',
                this.rent = '',
                this.selectUndertaker = ''
                this.selectName = '',
                this.prepareGoods = true
                let that = this
                let initData = {
                    did: that.did,
                    user_id: getStaffId()
                }
                saveDistributionOrderInit(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.personList = response.data.data.personList
                    })
                })
                //查询物品价格
                this.init(that.did);
            },

            init(id){
                var that = this
                let initData = {}
                initData.user_id = getStaffId()
                initData.id = id
                distributionDetailItemInit(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.tableList = response.data.data.tableList
                    })
                })
            },
            // 通过、驳回按钮
            choosePass() {
                if (this.isPass) {
                    return
                }
                this.radioSelect = '通过'
                this.accepteReason = ''
                this.fileList = [],
                    this.value = 1;
                this.workerName = '';
                this.isPass = true;
                this.isReject = false;
            },
            chooseReject() {
                if (this.isReject) {
                    return
                }
                this.radioSelect = '驳回'
                this.value = 1;
                this.refuseReasonName = '';
                this.description = ''
                this.isReject = true;
                this.isPass = false;
            },
            // 维护人选择事件
            clickacceptanceNameShow() {
                this.isNameShow = !this.isNameShow;
            },
            // 人员选项
            selectNameOption(index, event) {
                this.selectName = event.path[0].childNodes[0].data
                this.isNameShow = false
                this.isNameSelect = true
            },
            // 检查是否已选择承担方
            clickUndertakerShow() {
                this.isUndertakerShow = !this.isUndertakerShow;
            },
            clickWorkeShow() {
                this.isWorkerShow = !this.isWorkerShow

            },
            refuseReasonChoosetype(item) {
                console.log(item)
                this.refuseReasonName = item.dictionaryTitle
                this.refuseReasonId = item.id
                this.isRejectText = false
            },
            refuseReasonShow() {
                this.isRejectText = !this.isRejectText
            },

            workerChoosetype(item) {
                this.isWorkerShow = false
                this.isUndertakerSelect = true
                this.workerName = item.dictionaryTitle
                this.workerId = item.dictionaryValue
            },
            // 承担方选项
            selectUndertakerOption(index, event) {
                this.selectUndertaker = event.path[0].childNodes[0].data
                this.isUndertakerShow = false
                this.isUndertakerSelect = true

            },
            //日期选择组件 点击确认键后执行方法
            endPeriodConfirm(value) {
                var date = new Date(value)
                this.contractInfo.endPeriod = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
            },
            // 事件选择事件
            clickCheckInDateShow() {
                this.isCheckInDateShow = !this.isCheckInDateShow;
            },
            //日期选择组件 点击确认键后执行方法
            checkInDateConfirm(value) {
                var date = new Date(value)
                this.checkInDate = this.formatDate(date)
                this.time = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + 'T' + date.getHours() + ':' + date.getMinutes() + ':' + '00' + '.000Z'
                this.isCheckInDateShow = false

            },
            // 格式化日期方法
            formatDate(dateTime) {
                console.log(dateTime.getMinutes())
                let year = `${dateTime.getFullYear()}`;
                let month  = `${dateTime.getMonth() + 1}` ;
                let date = `${dateTime.getDate()}` ;
                let hours = `${dateTime.getHours()}` ; ;
                let minutes = `${dateTime.getMinutes()}` ; ;
                if(month < 10){
                    month = `0${dateTime.getMonth() + 1}`
                }
                if(date < 10){
                    date = `0${dateTime.getDate()}`
                }
                if(hours < 10){
                    hours = `0${dateTime.getHours()}`
                }
                if( minutes < 10){
                    minutes = `0${dateTime.getMinutes()}`
                }
                return year+ '/' + month+ '/' + date;
            },
            //日期选择组件 点击取消键后执行方法
            checkInDateCancel() {
                this.isCheckInDateShow = false
            },

            //关闭密码弹窗
            closeMobileModel() {
                // this.$emit('hideDialog');
                this.pwdShow = false
            },
        }
    }
</script>

<style lang="less" scoped>
    .cancelTest {
        margin-left: 20px;
    }

    /*处理反馈DIV*/
    .feedback {
        float: left;
        width: 92%;
        margin: 18px auto 30px;
        /*height: 150px;*/
        border-radius: 8px;
        background-color: #fff;
        padding-top: 8px;
        padding-bottom: 8px;
        margin: 0 5px 15px 15px;

        /*text-align: center;*/
        /*处理反馈DIV*/

        .feedbackText {
            /*margin: 18px 15px 15px;*/
            margin: 0 15px 10px;
            font-size: 13px;
            color: #9d9d9d;

        }

        .feedbackTextarea {
            width: 83%;
            display: block;
            margin: 0 auto;
            height: 60px;
            padding: 8px 10px 10px 10px;
            /*color: #e6e6e6;*/
            background-color: #f7f7f7;
            border-radius: 8px;
            border: none;
            font-size: 14px;
            resize: none;
        }

    }

    .preview-cover {
        /*position: absolute;*/
        box-sizing: border-box;
        bottom: 0;
        height: 100px;
        width: 100px;
        padding: 4px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        background: rgba(0, 0, 0, 0.3);
    }

    .uploader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        margin-left: 10px;
    }

    .uploadDiv {
        width: 100%;
        /*margin: 15px;*/
        border-radius: 8px;
        /*margin-left: 22px;*/
        margin: 5px auto;

        .btncc {
            background-color: #f7f7f7;
            width: 98px;
            height: 80px;
            border-radius: 8px;
            text-align: center;
            padding-top: 15px;
            /*margin-top: 10px;*/
            /*margin-left: 10px;*/

            i {
                font-size: 15px;
                color: #ddd;
                margin-top: 16px;
            }
        }
    }

    .part-uploadpic-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: white;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        width: 100%;
        margin: 0 auto;
    }

    .part-uploadpic-module {
        width: 100%;
        margin: 20px 8px;
    }

    .part-inputpart-cardpic-button {
        display: inline-block;
        background: url("../../../assets/images/grayAdd.png") center center no-repeat;
        width: 90px;
        height: 90px;
        border-radius: 8px;
        background-size: 30px;
        background-color: #fafafa;
        margin-left: 10px;
        margin-top: 10px;
    }

    .part-inputpart-cardpic-text {
        position: absolute;
        top: 72px;
        left: 33px;
        font-size: 12px;
        color: #d8d8d8;
    }

    //下拉菜单
    .downMenuTitle {
        width: 92%;
        display: flex;
        margin-top: 20px;
        margin-left: 15px;
        background-color: #f7f7f7;
        height: 25px;

        span {
            color: red;
            font-size: 13px;
        }

        .triangleUp, .triangleDown {
            width: 0;
            height: 0;
            margin-left: 10px;
            border-width: 3px;
            border-style: solid;
        }

        .triangleUp {
            margin-top: 7px;
            border-color: black transparent transparent transparent;
        }

        .triangleDown {
            margin-top: 3px;
            border-color: transparent transparent black transparent;
        }
    }

    .prepareGoods {
        width: 100%;
        height: auto;
        overflow: hidden;

        .van-overlay {
            background-color: rgba(80, 80, 80, .7);;
        }
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .block {
        width: 76%;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
    }

    /*    获取密码DIV*/
    .getPassword {
        background: url("../../../assets/images/ContactPerson-img.png") no-repeat center center,
        linear-gradient(to right, #FFC274, #FF5D3B);
        text-align: center;
        height: 50px;
        line-height: 53px;
        color: white;
        background-size: 100%;
        font-size: 16px;
    }

    /*    门锁密码*/
    .LockPassWord {
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 14px;
    }

    .passwordRule {
        width: 70%;
        margin: 20px auto;
        text-align: center;
        border: 0.5px solid #eeeeee;
    }

    /*传入的密码值*/
    .password {
        color: #ff8247;
        font-size: 20px;
        margin-top: 30px;
        font-family: SimSun;
    }
    .passwordText {
        color: black;
        font-size: 16px;
        margin-top: 20px;
        font-family: SimSun;
    }

    /*关闭按钮*/
    .closeImg {
        margin-top: 100px;
        height: 30px;
        width: 30px;
        border: 1px solid white;
        border-radius: 50%;
        background: url("../../../assets/images/GetDoorPassword-closeImg.png") no-repeat center center;
        background-size: 18px 18px;
    }

    /*弹窗样式*/
    .van-popup--bottom {
        /*z-index: 2004;*/
        overflow: hidden;
        background-color: #f8f8f8;
    }

    /*验收备注Div*/
    .row2 {
        width: 92%;
        /*float: left;*/
        /*position: relative;*/
        /*flex-direction: row;*/
        /*align-items: center;*/
        background-color: white;
        width: 345px;
        /*height: 240px;*/
        height: auto;
        border-radius: 8px;
        padding-bottom: 10px;
        margin: 15px 5px 15px 15px;

    }

    /*验收备注*/
    .acceptedRemake {
        margin: 10px 0 8px 15px;
        font-size: 14px;
        font-weight: bold;
    }

    .date-graytext {
        color: #ff5809;
        width: 100%;
        font-size: 14px;
        margin-top: 1px;
    }

    .date-dropDownList {
        margin-left: 15px;
        width: 92%;
        margin-right: 10px;
        position: absolute;
        z-index: 99;
        margin-top: 244px;
    }

    .date-dropDownListA {
        margin-left: 15px;
        width: 92%;
        margin-right: 10px;
        position: absolute;
        z-index: 99;
        margin-top: 130px;
    }

    .blockTitle {
        float: left;
        margin-right: 25px;
        font-size: 14px;
        font-weight: bold;
        width: 35%;
    }

    .rightText {
        margin-left: auto;
        font-size: 14px;
        color: #999999;
    }

    .star {
        margin-left: 8px;
        font-size: 14px;
        color: #999999;
    }

    .choosedStyle {
        color: #ff3c00;
        font-weight: bold;
    }

    .passText {
        flex: 1;
        padding-right: 7px;
    }

    .rejectText {
        padding-left: 7px;
        padding-right: 33px;
    }

    .normalBlock {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        position: relative;
        background-color: white;
        line-height: 1rem;
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 10px 15px 0 10px;
    }

    /*选择名称的div*/
    .part-inputpart-row {
        width: 92%;
        /*position: relative;*/
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: white;
        height: 50px;
        border-radius: 8px;
        margin: 0 auto;
        margin-bottom: 15px;
        /*margin: 0 5px 15px 15px;*/
    }

    /*备注输入框*/
    .remake {
        color: gray;
        width: 310px;
        border-radius: 10px;
        line-height: 20px;
        height: 70px;
        margin: 5px 10px 0 10px;
        background-color: #f8f8f8;
        font-size: 12px;
        border: none;
        padding-left: 10px;
        padding-top: 10px;
    }

    /*上传图片*/
    /*.uploadDiv{*/
    /*    display: flex;*/
    /*    justify-content: space-between;*/
    /*    !*margin: 10px 0 0 10px;*!*/
    /*    height: auto;*/
    /*    width: 100%;*/
    /*    !*width: 345px;*!*/
    /*    !*background: url("../../../assets/images/grayAdd.png")30px 30px;*!*/
    /*}*/
    .upload {
        border-radius: 10px;
        height: 80px;
        width: 80px;
        background-color: #f8f8f8;
        margin-left: 50px;
        float: left;
    }

    .upload_icon {
        height: 35px;
        width: 35px;
        margin: 10px 10px 2px 21px;
    }

    .upload_font {
        height: 25px;
        font-size: 12px;
        line-height: 2px;
        margin-left: 14px;
        color: gray;
    }

    .part-inputpart-row input {
        background-color: white;
        width: 100%;
    }

    .part-inputpart-row-header {
        font-weight: bold;
        font-size: 14px;
        width: 150px;
        /*display: inline-block;*/
    }

    .header {
        font-weight: bold;
        font-size: 14px;
        width: 150px;
    }

    .part-inputpart-row-graytext {
        color: black;
        width: 100%;
        font-size: 12px;
    }

    .part-inputpart-row-right {
        float: right;
        margin-right: 10px;
        font-size: 14px;
        color: #D8D8D8;
    }

    .part-inputpart-dropDownList {
        width: 100%;
        position: absolute;
        z-index: 99;
        margin-top: 50px;
    }

    .optionSelectedColor {
        color: #ff5d3b;
        margin-left: 10px;
    }

    .complaintTypedropDownList-option {
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: black;
        float: left;
        width: 345px;
        height: 35px;
        padding-top: 10px;
        margin-left: 15px;
    }

    .complaintTypedropDownList {
        width: 100%;
        position: absolute;
        z-index: 99;
        margin-top: 110px;
    }

    /*圆点样式---灰色*/
    .maker {
        color: #999999;
        float: left;
        font-size: 28px;
        margin: 0px 10px 0px 22px;
    }

    /*圆点样式---橘色*/
    .orange {
        color: #ff5809;
        float: left;
        font-size: 28px;
        margin: 0px 10px 0px 22px;
    }

    .makerText {
        color: #999999;
    }

    .orangeText {
        color: #ff5809;
    }

    .part-inputpart-row-right-timepick {
        width: 20px;
        margin-right: 10px;
    }

    .part-inputpart-dropDownList-option-selected {
        color: #ff5d3b;
    }

    /*选择三角图标的向上向下样式*/
    .part-inputpart-row-right-upArrow {
        width: 9px;
        transform: rotateX(180deg);
    }

    .part-inputpart-row-right-downArrow {
        width: 9px;
    }

    /*下拉菜单选项样式*/
    .part-inputpart-dropDownList-option {
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: black;
        float: left;
        width: 345px;
        height: 35px;
        padding-top: 10px;
        margin-left: 15px;
    }

    /*竖线样式*/
    .content-divide {
        font-size: 14px;
        color: #efefef;
        margin: 0 5px 0 5px;
    }

    /*保存按钮不可点击样式*/
    .saveButton_Disable {
        /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
        background-color: rgba(184, 184, 184, 0.2);
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 48px 50px 15px 15px;
        font-size: 18px;
        color: white;
        line-height: 1.22rem;
        text-align: center;
    }

    .saveButton_Enable {
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
        color: white;
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 45px 50px 45px 15px;
        font-size: 18px;
        line-height: 1.22rem;
        text-align: center;
    }

    .saveDisable {
        /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
        background-color: rgba(184, 184, 184, 0.2);
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 15px 50px 47px 15px;
        font-size: 18px;
        color: white;
        line-height: 1.22rem;
        text-align: center;
    }

    .saveEnable {
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
        color: white;
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 15px 50px 47px 15px;
        font-size: 18px;
        line-height: 1.22rem;
        text-align: center;
    }

    textarea {
        resize: none;
        color: gray;
        font-size: 12px;
        width: 320px;
        height: 80px;
        background: #f0f0f0;
        margin-left: 15px;
        border-radius: 10px;
        padding: 10px 0 0 10px;
        border: none;
    }

    .navbar {
        z-index: 10;
        background-color: #f8f8f8;
    }

    .search {
        margin: 10px 20px 0 20px;
        height: 35px;
        border-radius: 30px;
        background-color: #f4f4f4;
        display: flex;
        align-items: center;
    }

    .search-icon {
        width: 25px;
        height: 25px;
        margin-left: 10px;
        background-image: url("../../../assets/images/search-icon.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }

    input {
        font-size: 14px;
        margin-left: 15px;
        background-color: #f4f4f4;
        border: 0;
        width: 250px;
    }

    input::-webkit-input-placeholder {
        color: #d9d9d9;
    }

    .icon {
        width: 30px;
    }

    .bottom_iconDiv {
        margin-left: 15px;
    }

    .tel_IMg {
        width: 20px;
        height: 20px;
        margin-right: 20px;
        line-height: 28px;
    }

    .tel {
        width: 100%;
        height: 100%;
    }

    .pwd_IMG {
        /*float: left;*/
        width: 100%;
        height: 100%;
        /*margin-left: 15px;*/
        /*margin-top: 3px;*/
    }

    .reviews {
        /*margin-top: 15px;*/
        /*width: 345px;*/
        /*height: 260px;*/
        width: 92%;
        height: auto;
        margin: 0 auto;
    }

    .review {
        margin: 15px 0px;
        /*padding-bottom: 10px;*/
        overflow: hidden;
        width: 100%;
        height: auto;
        background-color: white;
        border-radius: 8px;
    }

    .buttonType {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: right;
        padding: 10px 0px;

    }

    .buttonTypeA {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        /*padding: 5px 0px;*/

    }

    .cancelBtnA {
        margin-left: 160px;
    }

    .cancelBtn {
        height: 30px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        font-size: 12px;
        border: 1px solid transparent;
        border-radius: 10px;
        background-image: linear-gradient(white, white),
        linear-gradient(to right, #ffc274, #ff5D3B);
        background-clip: padding-box, border-box;
        background-origin: border-box;
        color: #ff5809;

    }

    .btn {
        /*float: right;*/
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 80px;
        font-size: 12px;
        border: none;
        border-radius: 10px;
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
        color: white;
    }

    .top-title-green {
        /*margin-bottom: 4px;*/
        width: 46px;
        height: 15px;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px 0;
        background: linear-gradient(to right, #63cb96, #30d0ac);
        color: white;
    }

    .top-title-orange {
        width: 46px;
        height: 15px;
        font-size: 10px;
        border-radius: 6px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(to right, #ffbe72, #ff6c41);
        color: white;
    }
    .top-title-violet{
        width: 46px;
        height: 15px;
        font-size: 10px;
        border-radius: 6px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(149, 119, 222);
        color: white;        
    }
    .top-title-grey {
        /*margin-bottom: 4px;*/
        width: 46px;
        height: 15px;
        font-size: 10px;
        border-radius: 6px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(153, 153, 153, 1);
        color: white;
    }

    .top-title-blue {
        width: 46px;
        height: 15px;
        font-size: 10px;
        border-radius: 6px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(to right, #7BC7FF, #3B98FF);
        color: white;
    }

    .getPwd {
        font-size: 14px;
        float: left;
        color: #9a9a9a;
        margin-top: 6px;
    }

    .positionAdderss {
        display: flex;
        /*align-items: center;*/
        justify-content: left;
    }

    .position {
        margin: 5px 15px 15px 15px;

        /*height: 42px;*/
        text-align: left;
        font-size: 14px;
        font-weight: bold;
        width: 275px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

        /*float: left;*/
    }

    .position-span-right {
        color: black;
        font-size: 15px;
    }

    .reject {
        width: 315px;
        margin-left: 15px;
        padding: 10px 0px;
    }

    .sp {
        color: #9a9a9a;
        font-size: 13px;
        font-weight: normal;
    }

    .text {
        color: black;
        font-size: 14px;
        font-weight: bold;
    }

    .textTime {
        color: #ff5809;
        font-size: 14px;
        font-weight: bold;
    }

    .trashCanHide {
        display: none;
    }

    .trashCanShow {
        display: block;
    }

</style>
